<template>
  <div>
      <vue-editor
        :disabled="disabled"
        :placeholder="disabled ? '' : placeholder"
        v-model="value[propety]"
        :editorOptions="editorOptions"
        :customModules="editorModules"
        :editorToolbar="customToolbar"
      />
  </div>
</template>

<script>
import { ImageDrop } from 'quill-image-drop-module';
import ImageResize from 'quill-image-resize';

export default {
  name: 'QuillEditor',

  props: {
    value: {
      type: Object,
      required: true,
    },

    propety: {
      type: String,
      required: true,
    },

    disabled: {
      type: Boolean,
      default: false,
    },

    placeholder: {
      type: String,
      default: 'Digite uma descrição...',
    },

    name: {
      type: String,
      default: 'Descrição',
    },

    rules: {
      type: String,
      default: '',
    },

    editorModules: {
      type: Array,
      default() {
        return [
          { alias: 'imageDrop', module: ImageDrop },
          { alias: 'imageResize', module: ImageResize },
        ];
      },
    },

    editorOptions: {
      type: Object,
      default() {
        return {
          modules: {
            imageDrop: true,
            imageResize: {
              displaySize: true,
            },
          },
        };
      },
    },

    customToolbar: {
      type: Array,
      default() {
        return [
          ['bold', 'italic', 'underline', 'strike'],
          [{ list: 'ordered' }, { list: 'bullet' }],
          [{ indent: '-1' }, { indent: '+1' }], // outdent/indent
          [{ color: [] }, { background: [] }], // dropdown with defaults from theme
          [{ align: [] }, 'code-block'],
          ['link', 'image'],
        ];
      },
    },
  },
};
</script>

<style lang="scss">
.ql-editor {
  min-height: 90px !important;
}
.text-editor {
  color: black !important;
}
</style>
